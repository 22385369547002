<template>
  <div class="dataUpload-wrap">
    <div class="task-nav">
      <div style="flex: 1">
        <a-row :gutter="24">
          <a-form-model :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" class="ant-advanced-search-form">
            <a-col :span="8">
              <a-form-model-item label="限额月份">
                <a-month-picker style="width: 100%" format="YYYY-MM" v-model="searchMonth" />
              </a-form-model-item>
            </a-col>
          </a-form-model>
        </a-row>
      </div>

      <div class="button-box">
        <a-button type="primary" style="margin-right: 10px" @click="getUploadData('search')">查询</a-button>
        <a-button style="margin-right: 10px" @click="resetCondition">重置</a-button>
      </div>
    </div>
    <div class="table-wrap">
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="initPagination"
        @change="handleTableChange"
        :loading="loading"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
      >
        <template slot="type" slot-scope="type, record">
          <span> {{ initOptionsType(type, uploadTypeArr) }}</span>
        </template>
        <template slot="result" slot-scope="result, record">
          <span :class="result === '上传失败' ? 'errorTxt' : ''">{{ result }}</span>
        </template>
        <template slot="operation" slot-scope="operation, record">
          <span class="operation" @click="lookDetail(record)" v-if="roleFLag === 'TEACHING_STAFF_ADMIN'">编辑</span>
        </template>
      </a-table>
    </div>
    <a-modal title="编辑限额" :visible="visible" width="430px" @cancel="onCancel" @ok="editMonthFun">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="应用月份" :wrapper-col="{ span: 18 }">
          <a-month-picker disabled style="width: 100%" v-decorator="['month']" format="YYYY-MM" />
        </a-form-item>
        <a-form-item label="承接限额" :wrapper-col="{ span: 18 }">
          <a-input-number
            :min="1"
            :precision="0"
            placeholder="请输入正整数"
            v-decorator="['monthLimit', { rules: [{ required: true, message: '请输入承接限额!' }] }]"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import storage from 'store';

import { queryMonthLimit, updateMonthLimit } from '@/api/headTeacher';

export default {
  name: 'monthlyLimit',
  data() {
    return {
      userInfo: {},
      roleFLag: '',
      monthID: null,
      form: this.$form.createForm(this),
      visible: false,
      searchMonth: null,
      uploadType: '',
      fileName: '',
      loading: false,
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          fixed: 'left',
          dataIndex: 'num',
          customRender: (text, record, index) => `${(this.current - 1) * this.pageSize + (index + 1)}`,
        },
        {
          title: '应用月份',
          dataIndex: 'month',
          key: 'month',
        },
        {
          title: '月承接学员限额',
          dataIndex: 'monthLimit',
          key: 'monthLimit',
        },

        {
          title: '月剩余名额',
          dataIndex: 'remaining',
          key: 'remaining',
        },
        {
          title: '操作人',
          dataIndex: 'operatorName',
          key: 'operatorName',
        },
        {
          title: '更新日期',
          dataIndex: 'whenModified',
          key: 'whenModified',
          width: 220,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          with: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'operation' },
        },
      ],

      initPagination: {
        total: 0,
        current: 1,
        pageSize: 10, // 每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'], // 每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, // 分页中显示总的数据
      },
      pageNum: 1,
      pageSize: 10,
      current: 0,
      tableData: [],

      uploadTypeArr: [
        { label: '学员分层', value: 'STUDENT_RANK' },
        { label: '班主任业务数据', value: 'CLASSADMIN_PERFORMANCE' },
        { label: '班主任每月限额', value: 'CLASSADMIN_MONTH_LIMIT' },
      ],
    };
  },
  methods: {
    editMonthFun() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const params = {
            classAdminMonthLimitId: this.monthID,
            monthLimit: values.monthLimit,
          };
          updateMonthLimit(params).then((res) => {
            this.$message.success('修改成功');
            this.onCancel();
            this.getUploadData();
          });
        }
      });
    },
    lookDetail(record) {
      this.monthID = record.uuid;
      // 只能编辑当前以及之后月份的班主任限额
      const month = this.$moment(record.month).diff(this.$moment(), 'months');
      if (month < 0) {
        this.$message.warning('当前月份不可编辑班主任限额！');
        return false;
      }
      this.visible = true;
      setTimeout(() => {
        this.form.setFieldsValue({
          month: this.$moment(record.month).format('YYYY-MM'),
          monthLimit: record.monthLimit,
        });
      });
    },
    onCancel() {
      this.visible = false;
      this.form.resetFields();
    },
    initOptionsType(value, typeArr) {
      let name = '';
      typeArr?.forEach?.((item) => {
        if (item.value === value) {
          name = item.label;
        }
      });
      return name;
    },
    getUploadData(act) {
      if (act === 'search') {
        this.pageNum = 1;
      }
      const params = {
        month: this.searchMonth ? this.$moment(this.searchMonth).format('YYYY-MM') : null,
        classAdminId: this.$route.query.classAdminId,
        pageSize: this.pageSize,
      };
      this.loading = true;
      queryMonthLimit(this.pageNum, params).then((res) => {
        this.loading = false;
        this.tableData = res.data.content;
        this.current = res.data.currentPage;
        this.pageSize = res.data.pageSize;
        this.initPagination.total = res.data.totalCount;
        this.initPagination.current = res.data.currentPage;
      });
    },
    handleTableChange(data) {
      this.initPagination.current = data.current;
      this.initPagination.pageSize = data.pageSize;
      this.pageSize = data.pageSize;
      this.pageNum = data.current;
      this.getUploadData();
    },

    resetCondition() {
      this.searchMonth = null;
      this.getUploadData('search');
    },
  },
  mounted() {
    this.userInfo = storage.get('userInfo');
    // 判断按钮角色，只有教务主管才可以编辑
    this.roleFLag = this.userInfo.roleArr.find((role) => role === 'TEACHING_STAFF_ADMIN');

    this.getUploadData();
  },
};
</script>

<style lang="less" scoped>
.task-nav {
  background-color: #fff;
  margin-bottom: 8px;
  padding: 20px 0 0;
  display: flex;
  justify-content: space-between;
}
.button-box {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-top: 5px;
}
.table-wrap {
  padding: 20px;
  background: #fff;
}
.sel-navwrap {
  text-align: right;
  margin-bottom: 20px;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  border-radius: 2px;
  cursor: pointer;
  &:hover {
    border: 1px solid #00bf8a;
    color: #00bf8a;
  }
}
.progress {
  text-align: center;
}
.progress-txt {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  margin-top: 12px;
}
.progress-txt1 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 13px;
  text-align: center;
  margin-top: 8px;
}
.error-pic {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.errorTxt {
  color: red;
}
.operation {
  color: #04cb94;
  cursor: pointer;
}
</style>
